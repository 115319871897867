import React, { memo, useState, useEffect } from "react";
import "../styles/cityPageStackedCard.scss";
import hero from "../assets/images/hero2.jpg";
import HalfMoonImages from "./HalfMoonImages";


import forli from "../assets/images/cover_forli.jpg"
import modena from "../assets/images/cover_modena.jpg"
import piacenza from "../assets/images/cover_piacenza.jpg"
import ferrara from "../assets/images/cover_ferrara.jpg"

<svg
  width="25"
  height="40"
  viewBox="0 0 25 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.756693 39.666C-0.0535253 39.5162 -0.256121 38.4864 0.359539 37.9388C5.30204 33.5428 8.41556 27.135 8.41556 20C8.41556 12.865 5.30204 6.45721 0.359538 2.06116C-0.256121 1.51357 -0.0535275 0.483776 0.75669 0.333975C1.94301 0.114639 3.16604 0 4.41585 0C15.4615 0 24.4158 8.9543 24.4158 20C24.4158 31.0457 15.4615 40 4.41585 40C3.16604 40 1.94301 39.8854 0.756693 39.666Z"
    fill="black"
    fillOpacity="0.2"
  />
</svg>;

const CityPageStackedCard = (props) => {

  const cityImages = {
    forli: forli,
    modena: modena,
    piacenza: piacenza,
    ferrara: ferrara,
    // Add more cities here following the pattern
  };

  const [content, setContent] = useState(null)

  useEffect(() => {
    setContent(props.content)
  }, [props])

  function capitalizeFirstLetter(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }


  const getCityButtonHref = (cityName) => {
    const cityLinks = {
      piacenza: "https://laboratorioapertopiacenza.it/",
      ferrara: "https://laboratorioapertoferrara.it/",
      modena: "https://laboratorioapertomodena.it/",
      forli: "https://laboratorioapertoforli.it/",
      // Add more cities with respective links as needed
    };

    return cityLinks[cityName] || null;
  };

  return (
    <>

      <div className="cityPageStacked-card" style={{
        backgroundImage: `url(${cityImages[props?.cityName]
          })`,
      }}>
        <div className="shadow"> </div>
        <div className="content">
          <p className="title">{capitalizeFirstLetter(props?.cityName)}</p>
          {/* <p className="subtitle">subtitle</p> */}
        </div>
        {/* <HalfMoonImages images /> */}

        <div className="buttons">
          <a href={getCityButtonHref(props.cityName)} target="_blank">
            <div className="button ora">

              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#0085FF"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                    clipRule="evenodd"
                    fillOpacity="0.7"
                  />
                </svg>
              </div>
              <span> Visita il sito del Lab Aperto {capitalizeFirstLetter(props?.cityName)}</span>
            </div>
          </a>


        </div>
      </div>
    </>
  );
};

export default memo(CityPageStackedCard);

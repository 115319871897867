import React from "react";

import "../styles/halfMoonImages.scss";

const HalfMoonImages = (props) => {
  const { likes, number = 0 } = props;

  return (
    <div className="row">
      {props.images && portraits(likes)}
      {likeCounter(number)}
    </div>
  );
};

const portraits = (likes) => {
  return (
    <div className="people">
      {likes?.[0] ? (
        <div
          className="circle-avatar"
          style={{
            backgroundImage:
              likes && likes.length > 0 ? `url(${likes?.[0]})` : "none",
          }}
        />
      ) : null}
      {likes?.[1] ? (
        <svg version="1.1" className="svg1">
          <defs>
            <pattern id="img1" width="100%" height="100%">
              <image href={likes[1]} />
            </pattern>
          </defs>
          <path
            d="M0.756693 39.666C-0.0535249 39.5162 -0.25612 38.4864 0.359539 37.9388C5.30204 33.5428 8.41557 27.135 8.41557 20C8.41557 12.865 5.30204 6.45721 0.359539 2.06116C-0.256121 1.51357 -0.0535271 0.483776 0.756691 0.333975C1.94301 0.114639 3.16604 0 4.41585 0C15.4615 0 24.4158 8.9543 24.4158 20C24.4158 31.0457 15.4615 40 4.41585 40C3.16604 40 1.94301 39.8854 0.756693 39.666Z"
            fill="url(#img1)"
          />
        </svg>
      ) : null}
    </div>
  )
}

const likeCounter = (number) => {
  return (
    <div className="number">
      <svg
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.91602 1V8M8.41602 4.5H1.41602"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span> {number} </span>
    </div>
  )
}

export default HalfMoonImages;

export function carouselAnimation(openPosition) {
  return `calc(var(--margin) + -2 * (var(--col) * ${openPosition - 1} + 20px * ${openPosition <= 1 ? 0 : openPosition - 1}))`
}

// ----------------------------------------------------------------

export function cardAnimations(pos, openPosition, viewportWidth) {
  if (viewportWidth <= 1279) {
    if (pos === openPosition - 1) {
      return cardStatesTablet["open"]
    }
    else if (pos === openPosition) {
      return cardStatesTablet["next"]
    }
    else {
      return cardStatesTablet["closed"]
    }
  }
  else {
    if (pos === openPosition) {
      return cardStatesDesktop["open"]
    }
    else if (pos === openPosition + 1) {
      return cardStatesDesktop["next"]
    }
    else {
      return cardStatesDesktop["closed"]
    }
  }

}

const cardStatesDesktop = {
  "closed": {
    "width": "calc(var(--col) * 2 + 20px)",
    "type": "closed"
  },
  "next": {
    "width": "calc(var(--col) * 4 + 20px * 3)",
    "type": "next"
  },
  "open": {
    "width": "calc(var(--col) * 6 + 20px * 5)",
    "type": "open"
  }
}

const cardStatesTablet = {
  "closed": {
    "width": "calc(var(--col) * 2 + 20px)",
    "type": "closed"
  },
  "next": {
    "width": "calc(var(--col) * 4 + 20px * 3)",
    "type": "next"
  },
  "open": {
    "width": "calc(var(--col) * 4 + 20px * 3)",
    "type": "open"
  }
}

export function handleClicks(card, openPosition, setOpenPosition, viewportWidth) {

  let cardType = card.type

  switch (cardType) {
    case 'closed':
      handleSlides(card, openPosition, setOpenPosition, viewportWidth)
      break;
  }

}

function handleSlides(card, openPosition, setOpenPosition, viewportWidth) {

  let cardIndex = card.index
  let isCardNext = cardIndex > openPosition

  switch (isCardNext) {
    case true:
      handleNext(cardIndex, openPosition, setOpenPosition, viewportWidth)
      break;
    case false:
      handlePrev(cardIndex, openPosition, setOpenPosition, viewportWidth)
      break;
  }

}

function handlePrev(index, openPosition, setOpenPosition, viewportWidth) {
  if (viewportWidth <= 1279) {
    if (index === openPosition - 2) {
      setOpenPosition(openPosition - 1)
    }
  } else {
    if (index === openPosition - 1) {
      setOpenPosition(openPosition - 1)
    }
    else if (index === openPosition - 2) {
      setOpenPosition(openPosition - 2)
    }
  }
}

function handleNext(index, openPosition, setOpenPosition, viewportWidth) {
  if (viewportWidth <= 1279) {
    if (index === openPosition + 1) {
      setOpenPosition(openPosition + 1)
    }
  } else {
    if (index === openPosition + 2) {
      setOpenPosition(openPosition + 1)
    }
  }
}



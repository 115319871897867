//PACKS
import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/api";

//COMPONENTS
import BlurredBackground from "../components/BlurredBackground";
import HomepageVerticalSlider from '../components/HomepageVerticalSlider'
import DefaultContentSection from "../components/DefaultContentSection";
import StatisticsGrid from '../components/StatisticsGrid'
import Footer from '../components/Footer'
import useFetch from "../utils/useFetch";

export default function Video() {

  const lastVideos = useFetch('french/videos/last')
  const suggestedVideos = useFetch('french/videos/featured')

  const backgroundImage = lastVideos?.data?.data[0].attributes.preview_pict

  return (
    suggestedVideos && lastVideos &&
    <div>
      <BlurredBackground image={backgroundImage} />
      <div style={{ height: '88px' }} />
      <div className="fit">
        <HomepageVerticalSlider videos={lastVideos} />
        <div className='sectionSpacer' />
      </div>
      <DefaultContentSection content={{ title: "Suggeriti per te", cards: suggestedVideos }} color={"light"} />
      <DefaultContentSection content={{ title: "Ultimo aggiunto", cards: lastVideos }} color={"light"} />
      <div className="sectionSpacer sectionSpacer-white" />
      <div className='full'>
        <StatisticsGrid />
      </div>
      <div className='fit'>
        <Footer />
      </div>
    </div>
  )
}
export default function injectMeta(title, description, link) {

  facebookMeta(title, description, link)
  twitterMeta(title, description, link)

}

function facebookMeta(title, description, link) {

  const facebookTitle = document.createElement('meta')
  facebookTitle.property = 'og:title'
  facebookTitle.content = "Open Lab Digital - " + title

  const facebookDescription = document.createElement('meta')
  facebookDescription.property = 'og:description'
  facebookDescription.content = description

  const facebookImage = document.createElement('meta')
  facebookImage.property = 'og:image'
  facebookImage.content = link

  document.head.appendChild(facebookTitle);
  document.head.appendChild(facebookDescription);
  document.head.appendChild(facebookImage);

}

function twitterMeta(title, description, link) {

  const twitterTitle = document.createElement('meta')
  twitterTitle.property = 'twitter:title'
  twitterTitle.content = "Open Lab Digital - " + title

  const twitterDescription = document.createElement('meta')
  twitterDescription.property = 'twitter:description'
  twitterDescription.content = description

  const twitterImage = document.createElement('meta')
  twitterImage.property = 'twitter:image'
  twitterImage.content = link

  document.head.appendChild(twitterTitle);
  document.head.appendChild(twitterDescription);
  document.head.appendChild(twitterImage);

}

// if (title) {
//   const existingTitle = document.querySelector('title')
//   if (existingTitle) {
//     existingTitle.textContent = "Open Lab Digital - " + title
//   } else {
//     const metaTitle = document.createElement('title')
//     metaTitle.textContent = "Open Lab Digital - " + title
//     document.head.appendChild(metaTitle)
//   }
// }

// if (description) {
//   const metaDescription = document.createElement('meta');
//   metaDescription.name = 'description'
//   metaDescription.content = metaDescription
// }

// if (link) {
//   const metaLink = document.createElement('link')
//   metaLink.rel = 'icon'
//   metaLink.href = link
// }
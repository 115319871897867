import React, { memo, useState, useEffect } from "react";
import "../styles/stackedCard.scss";
import HalfMoonImages from "./HalfMoonImages";
import CardActions from "./CardActions";
import { useNavigate, useLocation } from "react-router-dom";
import { setModal } from "../utils/setModal";

const StackedCard = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const search = useLocation().search

  return (
    <>
      {data && (
        <div
          className="stacked-card"
          style={{ backgroundImage: `url(${data.attributes.preview_pict || data.attributes.cover_pict})` }}
          onClick={() => {
            setModal(data, navigate, location, search);
          }}
        >
          <div className="shadow" />
          <div className="content">
            <p className="title">{data.attributes.title}</p>
          </div>
          <HalfMoonImages
            images
            likes={data?.attributes?.photos_from_random_users_who_liked_this}
            number={data?.attributes?.like_count}
          />

          {
            <div className="buttons">
              {data.type === "video" ? (
                <>
                  <div className="button ora">
                    <span> Vedi ora</span>
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#0085FF"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                          clipRule="evenodd"
                          fillOpacity="0.7"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              ) : data.type === "activity" ? (
                <>
                  <button className="add-button" onClick={() => {
                    setModal(data, navigate, location, search);
                  }}>
                    <p>Scropi di più</p>
                  </button>
                </>
              ) : null}
              <CardActions
                isLiked={data.attributes.is_liked}
                type={data.type}
                id={data.id}
                slug={data.attributes?.slug}
              />
            </div>
          }
        </div>
      )}
    </>
  );
};

export default memo(StackedCard);

const addIcon = (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.0625 2.25V3.9375M12.9375 2.25V3.9375M2.25 14.0625V5.625C2.25 5.17745 2.42779 4.74823 2.74426 4.43176C3.06072 4.11529 3.48995 3.9375 3.9375 3.9375H14.0625C14.5101 3.9375 14.9393 4.11529 15.2557 4.43176C15.5722 4.74823 15.75 5.17745 15.75 5.625V14.0625M2.25 14.0625C2.25 14.5101 2.42779 14.9393 2.74426 15.2557C3.06072 15.5722 3.48995 15.75 3.9375 15.75H14.0625C14.5101 15.75 14.9393 15.5722 15.2557 15.2557C15.5722 14.9393 15.75 14.5101 15.75 14.0625M2.25 14.0625V8.4375C2.25 7.98995 2.42779 7.56073 2.74426 7.24426C3.06072 6.92779 3.48995 6.75 3.9375 6.75H14.0625C14.5101 6.75 14.9393 6.92779 15.2557 7.24426C15.5722 7.56073 15.75 7.98995 15.75 8.4375V14.0625"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
    >
      <path
        d="M5.5 1V8.63636M9 4.81818H2"
        stroke="#0085FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

// function buttons(type, hours, isAdded) {
//   if (type === 'Video') {
//     return (
//     <>
//                   <div className="button ora">
//               <span> Vedi ora</span>
//               <div className="icon">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                   fill="#0085FF"
//                   className="w-6 h-6"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
//                     clipRule="evenodd"
//                     fillOpacity="0.7"
//                   />
//                 </svg>
//               </div>
//             </div>
//             <div className="button scopri">
//               <svg
//                 width="12"
//                 height="12"
//                 viewBox="0 0 12 12"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M6 1.625V10.375M10.375 6H1.625"
//                   stroke="white"
//                   strokeWidth="1.5"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//               <span> Scopri di più </span>
//             </div>
//             <CardActions isLiked={data.is_liked} type={data.type} id={data.id} />

//     </> )
//   }
//   else if (type === "Activity") {
//     return <button className={'button button-activity button-added-' + isAdded}>
//       {isAdded ?
//         <div className="added">
//           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
//             <path d="M1 6.5L5 10.5L11 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
//           </svg>
//           <p>Aggiunto</p>
//         </div>
//         :
//         <p>Aggiungi</p>
//       }
//       <div className='separator' />
//       <p className='hours'>{hours}</p>
//     </button>
//   }
// }
//PACKS
import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import injectMeta from '../utils/injectMeta';

//STYLES
import "../styles/contentModal.scss"

//UTILS
import axiosInstance from "../utils/api";
import { redirectToCalendar } from '../utils/redirectToCalendar';
import { preventBodyScrolling } from '../utils/preventBodyScrolling';

//COMPONENTS
import CardActions from "../components/CardActions"
import FeaturedCard from "../components/FeaturedCard"

export default function ContentModal() {

  const location = useLocation()
  const navigate = useNavigate()

  const [key, setKey] = useState(null)
  const [slug, setSlug] = useState(null)

  const [content, setContent] = useState(null)
  const [recommendedContent, setRecommendedContent] = useState(null)

  const fetchData = async (key, slug) => {

    let response = null
    let contentResponse = null

    switch (key) {
      case 'activity':
        response = await axiosInstance.get(`/french/activities/${slug}`)
        contentResponse = response.data.data;
        // console.log(response.data.data)
        break;
      case 'video':
        response = await axiosInstance.get(`/french/videos/${slug}`)
        contentResponse = response.data.data;
        break;
      default:
        contentResponse = null
    }

    setContent(contentResponse)
    setRecommendedContent(response.data.activities)

  }

  function closeContent() {
    navigate(location.pathname)
    setKey(null)
    setSlug(null)
    preventBodyScrolling("unset")
  }

  useEffect(() => {
    if (location.search === "" && slug !== null) {
      setKey(null)
      setSlug(null)
      // required to prevent content to "preload" from the last fetch
      setContent(null)
    }
    const urlParams = new URLSearchParams(location.search);
    urlParams.forEach((value, key) => {
      if (key === 'video' || key === 'activity') {
        setKey(key)
        setSlug(value)
      }
    });
  }, [location])


  useEffect(() => {
    if (key === null || key === undefined) {
      return
    } else {
      fetchData(key, slug)
      preventBodyScrolling("hidden")
    }
  }, [key])

  useEffect(() => {

    if (!content) {
      return
    }

    injectMeta(
      content.attributes.title,
      content.attributes.description,
      content.attributes.preview_pict || content.attributes.cover_pict
    )
  }, [content])

  return (
    <AnimatePresence>
      {/* {key !== null && key !== undefined && content && recommendedContent && ( */}
      {key !== null && key !== undefined && content && (
        <>
          <div
            className="content-modal-shadow"
            onClick={() => {
              closeContent()
            }}
          />
          <motion.div
            className="content-modal"
            initial={{ opacity: 0, scale: 1, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: -20 }}
            exit={{ opacity: 0, scale: 1, y: 20 }}
            transition={{ duration: 0.2 }}
          >
            {topBar(content.type, content.attributes.formatted_date, closeContent, content)}
            {/* {hero(content.type, content.attributes.preview_pict ? content.attributes.preview_pict : content.attributes.cover_pict, content?.attributes.video)} */}
            <Hero type={content.type} media={content.attributes.preview_pict || content.attributes.cover_pict} video={content.attributes.video} />
            {description(content.id, content.type, content.attributes)}
            {/* <div className='separator' />
            {recommended(recommendedContent)} */}
            <div className='separator' />
            {ending()}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

const topBar = (type, formatted_date, closeContent, content) => {
  return (
    <div className={"topbar " + "topbar-" + type.toLowerCase()}>
      {type.toLowerCase() === 'activity' &&
        // <button className="add-to-calendar" onClick={(event) => { event.stopPropagation(); redirectToCalendar(content); console.log(content) }}>
        //   <div className="add">
        //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
        //       <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        //     </svg>
        //     <p className='default-add-text'>
        //       Add to calendar
        //     </p>
        //   </div>
        //   <div className='separator' />
        //   <p className='date'>
        //     {formatted_date}
        //   </p>
        // </button>
        <div></div>
      }
      <button className='close' onClick={() => { closeContent() }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  )
}

function Hero({ type, media, video }) {

  const sourceRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    if (!sourceRef.current || !containerRef.current) { return }
    sourceRef.current.setAttribute('src', video)
    containerRef.current.load()
  }, [video])

  switch (type) {
    case "activity":
      return (
        <img className="hero hero-activity" src={media} />
      )
    case "video":
      return (
        <div className='hero hero-video'>
          {/* <ReactPlayer url='https://www.youtube.com/watch?v=VukFxybhh7Y' width="100%" height="100%" /> */}
          <video width="100%" height="100%" controls poster={media} autoplay='true' ref={containerRef}>
            <source type="video/mp4" ref={sourceRef} />
          </video>
        </div>
      )
  }
}

const description = (id, type, content) => {
  return (
    <div className='description'>
      <div className='first-row'>
        <p className='title'>
          {content.title}
        </p>
        <CardActions isLiked={content.is_liked} type={type} id={id} slug={content.slug} />
      </div>
      {
        type === 'activity' &&
        <>
          <div className='organizer'>
            <p>
              Organizzato da
            </p>
            <p className='org'>
              {content.organizer_name}
            </p>
          </div>
          <div className='location'>
            <p>
              Dove:
            </p>
            <p className='org'>
              {content.location}
            </p>
          </div>
          <div className='time'>
            <p>
              Quando:
            </p>
            <p className='org'>
              {new Date(content.start_dt).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
            </p>
          </div>
        </>
      }
      <div className='second-row'>
        <p className='description'>
          {type === 'video' ? content.subtitle : content.description}
        </p>
      </div>
    </div>
  )
}

const recommended = (recommendedContent) => {
  return (
    recommendedContent &&
    <div className='recommended'>
      {recommendedContent?.map((item, id) => (
        <div key={id} className='recommended-card'>
          <FeaturedCard content={item} type="open" inModal={true} />
        </div>
      ))}
    </div>
  )
}

const ending = () => {
  return (
    <div className='ending'>
      <p>Open Lab Digital</p>
    </div>
  )
}